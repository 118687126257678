import { Button } from '@mui/material'
import { NextLinkComposed } from './nextLinkComposed'
import { useTranslation } from 'next-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
	getFacility,
	getInsurance,
	getUnit,
	setBookingInfo,
	setCartOpen
} from '../slices/bookingSlice'
import { setMoveinDate } from '../slices/searchSlice'
import dateUtil from '../util/date'
import stringUtil from '../util/string'

export const BookNowCTA = ({
	facilityId,
	unitId,
	parking = false,
	facilityOpenDate,
	unitAvailableDate,
	neighborUrl = '',
	text = 'book-now',
	size = 'medium',
	className,
	children,
	style,
	variant = 'outlined'
}) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	let moveInDate = useSelector((state) => state.search.moveInDate)
	if (!moveInDate) {
		dispatch(
			setMoveinDate(
				dateUtil.formatDateSlashesMMDDYYYY(dateUtil.returnFutureDate(1))
			)
		)
	}
	const hasDatePassed = dateUtil.hasDatePassed(moveInDate)
	// If the moveInDate is yesterday or today
	if (hasDatePassed) {
		// Set the moveInDate for booking to tomorrow
		moveInDate = dateUtil.formatDateSlashesMMDDYYYY(
			dateUtil.returnFutureDate(1)
		)
	}
	// If a valid facilityOpenDate was passed
	// And the facilityOpenDate is not a date in the past
	// And the facilityOpenDate is after the moveInDate
	if (
		!stringUtil.isNullOrUndefined(facilityOpenDate) &&
		!dateUtil.hasDatePassed(
			dateUtil.formatStringWithoutTimezone(facilityOpenDate)
		) &&
		dateUtil.isAfterDate(
			dateUtil.formatStringWithoutTimezone(facilityOpenDate),
			moveInDate
		)
	) {
		// Set the moveInDate to the facility open date
		moveInDate = dateUtil.formatStringWithoutTimezone(facilityOpenDate)
	}

	// If a valid unitAvailableDate was passed
	// And the unitAvailableDate is not a date in the past
	if (
		!stringUtil.isNullOrUndefined(unitAvailableDate) &&
		!dateUtil.hasDatePassed(
			dateUtil.formatStringWithoutTimezone(unitAvailableDate)
		)
	) {
		// Set the moveInDate to the facility open date
		moveInDate = dateUtil.formatStringWithoutTimezone(unitAvailableDate)
	}

	if (parking) {
		return (
			<Button
				data-cy='bookNowCTA' // Used for cypress e2e tests
				component={NextLinkComposed}
				to={neighborUrl} // Ignore parking spaces, set info for store units only
				onClick={(e) => {
					// If current search moveInDate has passed
					if (hasDatePassed) {
						// Update moveInDate in search slice
						dispatch(
							setMoveinDate(
								dateUtil.formatDateSlashesMMDDYYYY(dateUtil.returnFutureDate(1))
							)
						)
					}
				}}
				target={'_blank'}
				variant={variant}
				size={size}
				sx={(theme) => ({
					whiteSpace: 'nowrap',
					borderRadius: theme.shape.borderRadiusFullRounded,
					borderWidth: theme.shape.borderWidth,
					'&:hover': {
						borderWidth: theme.shape.borderWidth
					}
				})}
				className={className}
				style={style}
			>
				{text && t(text, { ns: 'common' })}
				{!text && children}
			</Button>
		)
	}

	return (
		<Button
			data-cy='bookNowCTA' // Used for cypress e2e tests
			onClick={(e) => {
				// Add unit to cart
				dispatch(
					setBookingInfo({
						facilityId,
						unitId,
						moveInDate: dateUtil.formatDateDashesMMDDYYYY(moveInDate),
						insurance_id: null,
						membership_id: null
					})
				)
				dispatch(getFacility(facilityId))
				dispatch(getUnit(unitId))
				dispatch(getInsurance(facilityId))
				dispatch(setCartOpen(true))

				// If current search moveInDate has passed
				if (hasDatePassed) {
					// Update moveInDate in search slice
					dispatch(
						setMoveinDate(
							dateUtil.formatDateSlashesMMDDYYYY(dateUtil.returnFutureDate(1))
						)
					)
				}
			}}
			variant={variant}
			size={size}
			sx={(theme) => ({
				whiteSpace: 'nowrap',
				borderRadius: theme.shape.borderRadiusFullRounded,
				borderWidth: theme.shape.borderWidth,
				'&:hover': {
					borderWidth: theme.shape.borderWidth
				}
			})}
			className={className}
			style={style}
		>
			{text && t(text, { ns: 'common' })}
			{!text && children}
		</Button>
	)
}
