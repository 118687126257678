import { useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import { Check, Cube, Star, Tag } from '@phosphor-icons/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

import {
	getFacility,
	getInsurance,
	getUnit,
	setBookingInfo,
	setCartOpen
} from '../slices/bookingSlice'
import { BookNowCTA } from '../atoms/bookNowCTA'
import HubspotContactForm from '../atoms/hubspot-contact-form'
import dateUtil from '../util/date'
import menuOptions from '../util/menuOptions'
import stringUtil from '../util/string'
import unitUtil from '../util/unit'
import { TRANSLATIONS, UNIT } from '../util/constants'
import storageImg from '../public/Storage_Units.png'

const FacilityCardSearch = ({ facility, onHover }) => {
	const theme = useTheme()
	const { t } = useTranslation(TRANSLATIONS)
	const router = useRouter()
	const dispatch = useDispatch()

	const [openWaitlist, setOpenWaitlist] = useState(false)
	const [openUnits, setOpenUnits] = useState(false)

	const filterDate = useSelector((state) => state.search.filterList.date)

	const units = unitUtil.getUnitsOptions(facility.units, t)

	const hasUnits =
		facility?.starting_at.small ||
		facility?.starting_at.medium ||
		facility?.starting_at.large

	const lastUnit =
		facility.units?.length === 1 &&
		facility.units[0]?.id.length === 1 &&
		dateUtil.hasDatePassed(facility.units[0]?.availableDate)

	const smallUnit = facility.units?.find(
		(unit) => unit.sizeCategory === parseInt(UNIT.SIZE_OPTIONS[1].id)
	)
	const mediumUnit = facility.units?.find(
		(unit) => unit.sizeCategory === parseInt(UNIT.SIZE_OPTIONS[2].id)
	)
	const largeUnit = facility.units?.find(
		(unit) => unit.sizeCategory === parseInt(UNIT.SIZE_OPTIONS[3].id)
	)
	const discountText = facility.units[0]?.textDiscount

	const startingAtText = [
		...(facility.starting_at.small
			? [`S $${facility?.starting_at.small}`]
			: []),
		...(facility.starting_at.medium
			? [`M $${facility?.starting_at.medium}`]
			: []),
		...(facility.starting_at.large ? [`L $${facility?.starting_at.large}`] : [])
	]

	const handleOpenUnit = () => {
		setOpenUnits(true)
	}

	const handleCloseUnit = () => {
		setOpenUnits(false)
	}

	const navigateFromUnit = (unit) => {
		const availableDate = dateUtil.formatDateDashesMMDDYYYYV2(
			unit.availableDate
		)
		dispatch(
			setBookingInfo({
				facilityId: facility.id,
				unitId: unit.unitID,
				moveInDate: availableDate > filterDate ? availableDate : filterDate,
				insurance_id: null,
				membership_id: null
			})
		)
		setOpenUnits(false)
		dispatch(getFacility(facility.id))
		dispatch(getUnit(unit.unitID))
		dispatch(getInsurance(facility.id))
		dispatch(setCartOpen(true))
	}

	return (
		<>
			<div
				className={`shadow rounded-xl mb-6 lg:p-4 bg-white lg:border-2 border-white min-h-[330px] flex flex-col ${
					!!onHover && 'hover:border-brand-blue'
				}`}
				onMouseEnter={() => !!onHover && onHover(facility.id)}
			>
				<div className='lg:flex flex-1'>
					<div className='lg:w-2/5 lg:flex lg:flex-col lg:border-r lg:pr-4'>
						<div className='w-full h-full relative rounded-t-xl lg:rounded-xl facility-card-carousel flex-1 flex-grow-0'>
							{facility?.images ? (
								<Swiper
									slidesPerView={1}
									modules={[Navigation]}
									navigation={true}
								>
									{facility?.images?.map((image, index) => {
										return (
											<SwiperSlide key={index}>
												<Image
													alt='Facility Image'
													src={image}
													width={327}
													height={170}
													sizes='100vw'
													className='h-[170px] w-full object-cover rounded-t-xl lg:rounded-xl'
												/>
											</SwiperSlide>
										)
									})}
								</Swiper>
							) : (
								<Image
									alt=''
									src={'/unit-stock-img.jpeg'}
									layout='responsive'
									width={327}
									height={170}
									sizes='100vw'
									className='h-[170px] w-[327px] rounded-t-xl'
								/>
							)}
							{facility?.tag_name && !facility.easymove_primary_location && (
								<div className='absolute left-4 bottom-4 bg-white font-serif text-xs rounded-full py-2 px-3 z-10 border border-brand-charcoal-dark lg:hidden'>
									{facility?.tag_name}
								</div>
							)}
						</div>
						<div className='p-4 lg:px-0'>
							<Link
								legacyBehavior
								href={`/${stringUtil.formatStringForUrl(
									facility?.state_name
								)}-units-near-me/${facility?.seo_location_url}`}
							>
								<a className='flex justify-between items-center text-xl font-semibold cursor-pointer'>
									{facility?.address}
									{parseFloat(facility?.average_rating) >= 4 ? (
										<div className='flex gap-2 items-center font-serif font-normal text-base'>
											<Star
												size={24}
												weight='fill'
												color={theme.palette.primary.main}
											/>
											{facility?.average_rating}
										</div>
									) : null}
								</a>
							</Link>
							<div className='font-serif text-brand-graphite mb-3'>
								{facility?.city_name}{' '}
								{!stringUtil.isWashingtonDC(facility?.city_name) &&
									facility?.state_abbr}{' '}
								{facility?.zip}
							</div>
							{discountText && (
								<div className='flex gap-2 font-serif items-center'>
									<div>
										<Tag
											size={24}
											weight='fill'
											color={theme.palette.primary.main}
										/>
									</div>
									{/* TODO: Change for discount text */}
									<div className='text-sm'>{discountText}</div>
								</div>
							)}
							{hasUnits && !lastUnit && (
								<div className='flex gap-2 font-serif items-center lg:hidden'>
									<Cube
										size={24}
										weight='fill'
										color={theme.palette.primary.main}
									/>
									{t('starts-at', { ns: 'common' })}:{' '}
									{startingAtText.join(' • ')}
								</div>
							)}
							{hasUnits && lastUnit && (
								<div className='flex gap-2 font-serif items-center lg:hidden'>
									<Cube
										size={24}
										weight='fill'
										color={theme.palette.primary.main}
									/>
									{t(
										unitUtil.getSizeCategoryFromId(
											facility.units[0]?.sizeCategory
										),
										{
											ns: 'common'
										}
									)}{' '}
									{facility.units[0]?.width}
									{' x '}
									{facility.units[0]?.length}
									{' - '}
									{facility.units[0]?.priceDiscount && (
										<span className='text-brand-light-grey-3 line-through'>
											${facility.units[0]?.price}
										</span>
									)}
									$
									{facility.units[0]?.priceDiscount
										? facility.units[0]?.priceDiscount
										: facility.units[0]?.price}
								</div>
							)}
							<div className='flex justify-between items-center mt-3 lg:hidden'>
								<Button
									sx={{ textDecoration: 'underline' }}
									onClick={(event) => event.stopPropagation()}
								>
									<Link
										legacyBehavior
										href={`/${stringUtil.formatStringForUrl(
											facility?.state_name
										)}-units-near-me/${facility?.seo_location_url}`}
									>
										{t('view-details', { ns: 'dashboard' })}
									</Link>
								</Button>
								{hasUnits ? (
									lastUnit ? (
										<BookNowCTA
											facilityId={facility?.id}
											unitId={facility.units[0]?.id}
											facilityOpenDate={facility?.open_date}
											unitAvailableDate={facility.units[0]?.availableDate}
											variant='contained'
										/>
									) : (
										<Button
											variant='contained'
											sx={{ borderRadius: '99px' }}
											onClick={handleOpenUnit}
										>
											{t('select-a-unit', { ns: 'common' })}
										</Button>
									)
								) : (
									<Button
										variant='contained'
										sx={{ borderRadius: '99px' }}
										onClick={() => setOpenWaitlist(true)}
									>
										{t(
											facility.easymove_primary_location
												? 'get-a-quote'
												: 'join-waitlist',
											{ ns: 'common' }
										)}
									</Button>
								)}
							</div>
						</div>
					</div>
					<div className='flex-col justify-center px-4 py-6 font-serif w-3/5 hidden lg:flex'>
						{!facility.easymove_primary_location && (
							<>
								{smallUnit && (
									<>
										<div className='text-xs leading-3 text-brand-graphite'>
											{t('starts-at', { ns: 'common' })}
										</div>
										<div className='flex justify-between items-center'>
											<div>
												S ({smallUnit.width}' x {smallUnit.length}') -{' '}
												{smallUnit.priceDiscount && (
													<span className='text-brand-graphite line-through'>
														${smallUnit.price}
													</span>
												)}
												{' $'}
												{smallUnit.priceDiscount
													? smallUnit.priceDiscount
													: smallUnit.price}
											</div>
											<BookNowCTA
												variant='text'
												style={{ textDecoration: 'underline' }}
												facilityId={facility?.id}
												unitId={smallUnit.id}
												facilityOpenDate={facility?.open_date}
												unitAvailableDate={smallUnit.availableDate}
											/>
										</div>
									</>
								)}
								{mediumUnit && (
									<>
										<div className='text-xs leading-3 text-brand-graphite'>
											{t('starts-at', { ns: 'common' })}
										</div>
										<div className='flex justify-between items-center'>
											<div>
												M ({mediumUnit.width}' x {mediumUnit.length}') -{' '}
												{mediumUnit.priceDiscount && (
													<span className='text-brand-graphite line-through'>
														${mediumUnit.price}
													</span>
												)}
												{' $'}
												{mediumUnit.priceDiscount
													? mediumUnit.priceDiscount
													: mediumUnit.price}
											</div>
											<BookNowCTA
												variant='text'
												style={{ textDecoration: 'underline' }}
												facilityId={facility?.id}
												unitId={mediumUnit.id}
												facilityOpenDate={facility?.open_date}
												unitAvailableDate={mediumUnit.availableDate}
											/>
										</div>
									</>
								)}
								{largeUnit && (
									<>
										<div className='text-xs leading-3 text-brand-graphite'>
											{t('starts-at', { ns: 'common' })}
										</div>
										<div className='flex justify-between items-center'>
											<div>
												L ({largeUnit.width}' x {largeUnit.length}') -{' '}
												{largeUnit.priceDiscount && (
													<span className='text-brand-graphite line-through'>
														${largeUnit.price}
													</span>
												)}
												{' $'}
												{largeUnit.priceDiscount
													? largeUnit.priceDiscount
													: largeUnit.price}
											</div>
											<BookNowCTA
												variant='text'
												style={{ textDecoration: 'underline' }}
												facilityId={facility?.id}
												unitId={largeUnit.id}
												facilityOpenDate={facility?.open_date}
												unitAvailableDate={largeUnit.availableDate}
											/>
										</div>
									</>
								)}
							</>
						)}
						{facility.units.length > 0 &&
						!facility.easymove_primary_location ? (
							<Button
								variant='contained'
								sx={{
									borderRadius: '99px',
									mt: 'auto',
									textTransform: 'capitalize',
									fontWeight: 'regular'
								}}
								onClick={(event) => event.stopPropagation()}
							>
								<Link
									legacyBehavior
									href={`/${stringUtil.formatStringForUrl(
										facility?.state_name
									)}-units-near-me/${facility?.seo_location_url}`}
								>
									{t('see-all-units', { ns: 'common' })}
								</Link>
							</Button>
						) : (
							<>
								{facility.easymove_primary_location ? (
									<div className='m-auto'>
										<div className='flex gap-2 mb-3'>
											<Check size={24} />
											{t('free-moving-services-provided-by-Lugg', {
												ns: 'common'
											})}
										</div>
										<div className='flex gap-2 mb-3'>
											<Check size={24} />
											{t('convenient-location-within-the-metro-area', {
												ns: 'common'
											})}
										</div>
										<div className='flex gap-2 mb-10'>
											<Check size={24} />
											{t('guaranteed-access-during-operating-hours', {
												ns: 'common'
											})}
										</div>{' '}
									</div>
								) : (
									<div className='flex flex-col justify-center items-center'>
										<Image alt='facility' src={storageImg}></Image>
										<p className='font-bold leading-none my-3'>
											{t('there-are-no-units-left', { ns: 'common' })}
										</p>{' '}
									</div>
								)}

								<Button
									variant='contained'
									sx={{
										borderRadius: '99px',
										mt: 'auto',
										textTransform: 'capitalize',
										fontWeight: 'regular'
									}}
									onClick={() => setOpenWaitlist(true)}
								>
									{t(
										facility.easymove_primary_location
											? 'get-a-quote'
											: 'join-waitlist',
										{ ns: 'common' }
									)}
								</Button>
							</>
						)}
					</div>
				</div>
			</div>
			<Dialog open={openWaitlist} onClose={() => setOpenWaitlist(false)}>
				<div className=' bg-white p-4'>
					<HubspotContactForm
						formId={facility?.waitlist_form_id}
						onSubmit={() => setOpenWaitlist(false)}
						waitlistLocation={`${facility?.location_code} ${facility?.business_name}`}
					/>
				</div>
			</Dialog>
			<Dialog
				open={openUnits}
				onClose={handleCloseUnit}
				fullWidth
				PaperProps={{ sx: { borderRadius: '16px' } }}
			>
				<div className='bg-white py-6 px-3 rounded-2xl w-full'>
					<div className='font-semibold text-2xl px-3'>
						{t('select-a-unit', { ns: 'common' })}
					</div>
					{menuOptions.renderCustomOptions(
						units,
						null,
						true,
						true,
						true,
						null,
						'',
						navigateFromUnit
					)}
				</div>
			</Dialog>
		</>
	)
}

export default FacilityCardSearch
