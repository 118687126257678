import { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

const HubspotContactForm = ({formId, onSubmit, waitlistLocation}) => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src='https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID,
                    formId: formId,
                    target: '#hubspotForm',
                    onFormSubmitted: onSubmit,
                    onFormReady: function(form) {
                        const location = form.querySelector('input[name="waitlist_location"]')
                        if(!location) return
                        location.value = waitlistLocation
                        location.dispatchEvent(new Event('input', { bubbles: true }))
                    }
                })
            }
        });
    }, []);

    return (
        <div id="hubspotForm">
            <div className='flex justify-center'>
                <CircularProgress color='primary' />
            </div>
        </div>
    )
}

export default HubspotContactForm